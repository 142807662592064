@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}
svg > rect:first-child {
  fill-opacity: 0 !important;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}
.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

/*  tui charts**/
.tui-chart .tui-chart-chartExportMenu-area {
  z-index: 1 !important;
}

.is-invalid ~ .invalid-feedback-custom {
  display: block;
}

.invalid-feedback-custom {
  color: #f46a6a;
  display: none;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
}

.is-invalid .select2-selection__control {
  border-color: #f46a6a !important;
}
.custom-shipping-addr .select2-selection__value-container {
  height: 80px;
}

.avatar-order {
  width: 80px;
  height: 80px;
  border: 1px solid #7f8387;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-order i {
  font-size: 50px;
}

.main-landing {
  width: 800px;
  margin-top: 100px !important;
  margin: 0 auto;
}
.main-label {
  font-size: 16px;
}
.main-label-big {
  font-size: 30px;
}
@media (max-width: 991.98px) {
  .topnav {
    margin-top: 70px;
  }
  .search-box {
    display: block !important;
    .search-label {
      float: none;
      width: 100%;
      .form-control {
        border-radius: 0.25rem !important;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  // height: 35px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  // font-size: 12px;
  border: 1px solid rgba(58, 58, 58, 0.15);
  border-radius: 4px;

  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.react-autosuggest__input--focused {
  outline: none;
}

.g-search .react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid rgba(58, 58, 58, 0.15) !important;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid rgba(58, 58, 58, 0.15) !important;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;

  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1px solid #f3f3f3 !important;

  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

.is-invalid .react-autosuggest__container {
  border: 1px solid #f46a6a;
}

.search-input-hero {
  padding: 10px 25px 10px 25px;
  border-radius: 50px;
  font-size: 14px;
}

.text-truncate-2lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.right-sidebar {
  position: absolute;
  right: -466px;
}
.video-full {
  height: 600px;
}

.megamenu-item:hover {
  background-color: #efe9ef;
  cursor: pointer;
}

.card-hover:hover,
.sp-card:hover {
  background-color: #efe9ef;
  cursor: pointer;
  transform: scale(1.08);
  transition: transform 0.2s;
}

.chevron-hover:hover {
  filter: none;
  cursor: pointer;
  transform: scale(1.08);
  transition: transform 0.2s;
  filter: brightness(1.3);
}

.card-hover-border:hover,
.card-module-img:hover {
  transform: scale(1.08);
  transition: transform 0.2s;
}

@media (min-width: 1200px) {
  .container-fluid {
    max-width: 95% !important;
  }
  .dropdown-megamenu {
    width: 1024px;
  }
}

@media (max-width: 600px) {
  .dropdown-megamenu {
    // max-width: 90%;
  }
}

.say-button-container button {
  border: none;
  background-color: transparent;
}

.say-button-container button:hover {
  border: none;
  background-color: transparent;
  color: purple;
  cursor: pointer;
  transform: scale(1.08);
  transition: transform 0.2s;
}

.mini-lesson-btn {
  --spacing-50: 4px;
  --spacing-100: 8;
  --spacing-150: 12px;
  --spacing-200: 16px;
  --spacing-250: 20px;
  --spacing-300: 24px;
  --spacing-350: 28px;
  --spacing-400: 32px;
  --spacing-450: 36px;
  --spacing-500: 40px;
  --spacing-550: 44px;
  --spacing-600: 48px;
  --sampler-sem-radius-none: 0;
  --sampler-sem-radius-sm: 4px;
  --sampler-sem-radius-lg: 20px;
  --sampler-sem-radius-circle: 100%;
  --sampler-sem-radius-xsm: 2px;
  --sampler-sem-radius-md: 8px;
  --sampler-sem-radius-xmd: 12px;
  --sampler-sem-radius-xxmd: 16px;
  --sampler-theme-border-radius: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-sm-keyhint: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-keyhint: var(--sampler-sem-radius-circle);
  --sampler-comp-radius-sm-ranking-drpdwn: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-ranking-drpdwn: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-matrix: 24px;
  --sampler-comp-radius-lg-picchoice: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-wrapper: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-lg-fileupl-file: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-file-touch: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-none-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-sm-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-payment: 34px;
  --sampler-comp-radius-lg-footer: 116px;
  --sampler-comp-radius-lg-footer-touch: 18px;
  visibility: visible;
  font: inherit;
  overflow: visible;
  text-transform: none;
  appearance: button;
  position: relative;
  font-family: inherit;
  line-height: inherit;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  padding: 6px 14px;
  min-height: 40px;
  // background-color: #5b235c;
  background: linear-gradient(#5b235c, #7d5a7e);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  width: 100%;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

.mini-lesson-nav-up {
  --spacing-50: 4px;
  --spacing-100: 8;
  --spacing-150: 12px;
  --spacing-200: 16px;
  --spacing-250: 20px;
  --spacing-300: 24px;
  --spacing-350: 28px;
  --spacing-400: 32px;
  --spacing-450: 36px;
  --spacing-500: 40px;
  --spacing-550: 44px;
  --spacing-600: 48px;
  --sampler-sem-radius-none: 0;
  --sampler-sem-radius-sm: 4px;
  --sampler-sem-radius-lg: 20px;
  --sampler-sem-radius-circle: 100%;
  --sampler-sem-radius-xsm: 2px;
  --sampler-sem-radius-md: 8px;
  --sampler-sem-radius-xmd: 12px;
  --sampler-sem-radius-xxmd: 16px;
  --sampler-theme-border-radius: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-sm-keyhint: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-keyhint: var(--sampler-sem-radius-circle);
  --sampler-comp-radius-sm-ranking-drpdwn: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-ranking-drpdwn: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-matrix: 24px;
  --sampler-comp-radius-lg-picchoice: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-wrapper: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-lg-fileupl-file: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-file-touch: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-none-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-sm-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-payment: 34px;
  --sampler-comp-radius-lg-footer: 116px;
  --sampler-comp-radius-lg-footer-touch: 18px;
  user-select: none;
  pointer-events: auto;
  white-space: nowrap;
  font: inherit;
  overflow: visible;
  text-transform: none;
  appearance: button;
  position: relative;
  font-family: inherit;
  line-height: inherit;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  padding: 0px;
  width: 36px;
  min-width: 36px;
  height: 32px;
  -webkit-box-pack: center;
  justify-content: center;
  // background-color: #5b235c;
  background: linear-gradient(#5b235c, #7d5a7e);
  color: rgb(255, 255, 255);
  border-radius: 4px 0px 0px 4px;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

.mini-lesson-nav-down {
  --spacing-50: 4px;
  --spacing-100: 8;
  --spacing-150: 12px;
  --spacing-200: 16px;
  --spacing-250: 20px;
  --spacing-300: 24px;
  --spacing-350: 28px;
  --spacing-400: 32px;
  --spacing-450: 36px;
  --spacing-500: 40px;
  --spacing-550: 44px;
  --spacing-600: 48px;
  --sampler-sem-radius-none: 0;
  --sampler-sem-radius-sm: 4px;
  --sampler-sem-radius-lg: 20px;
  --sampler-sem-radius-circle: 100%;
  --sampler-sem-radius-xsm: 2px;
  --sampler-sem-radius-md: 8px;
  --sampler-sem-radius-xmd: 12px;
  --sampler-sem-radius-xxmd: 16px;
  --sampler-theme-border-radius: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-sm-keyhint: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-keyhint: var(--sampler-sem-radius-circle);
  --sampler-comp-radius-sm-ranking-drpdwn: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-ranking-drpdwn: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-matrix: 24px;
  --sampler-comp-radius-lg-picchoice: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-wrapper: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-lg-fileupl-file: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-file-touch: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-none-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-sm-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-payment: 34px;
  --sampler-comp-radius-lg-footer: 116px;
  --sampler-comp-radius-lg-footer-touch: 18px;
  user-select: none;
  pointer-events: auto;
  white-space: nowrap;
  font: inherit;
  overflow: visible;
  text-transform: none;
  appearance: button;
  position: relative;
  font-family: inherit;
  line-height: inherit;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  padding: 0px;
  width: 36px;
  min-width: 36px;
  height: 32px;
  -webkit-box-pack: center;
  justify-content: center;
  // background-color: #5b235c;
  background: linear-gradient(#5b235c, #7d5a7e);
  color: rgb(255, 255, 255);
  border-radius: 0px 4px 4px 0px;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

.mini-lesson-p {
  --spacing-50: 4px;
  --spacing-100: 8;
  --spacing-150: 12px;
  --spacing-200: 16px;
  --spacing-250: 20px;
  --spacing-300: 24px;
  --spacing-350: 28px;
  --spacing-400: 32px;
  --spacing-450: 36px;
  --spacing-500: 40px;
  --spacing-550: 44px;
  --spacing-600: 48px;
  --sampler-sem-radius-none: 0;
  --sampler-sem-radius-sm: 4px;
  --sampler-sem-radius-lg: 20px;
  --sampler-sem-radius-circle: 100%;
  --sampler-sem-radius-xsm: 2px;
  --sampler-sem-radius-md: 8px;
  --sampler-sem-radius-xmd: 12px;
  --sampler-sem-radius-xxmd: 16px;
  --sampler-theme-border-radius: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-sm-keyhint: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-keyhint: var(--sampler-sem-radius-circle);
  --sampler-comp-radius-sm-ranking-drpdwn: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-ranking-drpdwn: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-matrix: 24px;
  --sampler-comp-radius-lg-picchoice: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-wrapper: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-lg-fileupl-file: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-file-touch: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-none-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-sm-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-payment: 34px;
  --sampler-comp-radius-lg-footer: 116px;
  --sampler-comp-radius-lg-footer-touch: 18px;
  font-family: inherit;
  visibility: visible;
  text-align: left;
  overflow-wrap: break-word;
  color: rgb(39, 39, 39);
  font-weight: unset;
  font-size: 20px;
  line-height: 32px;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

.mini-lesson-p {
  --spacing-50: 4px;
  --spacing-100: 8;
  --spacing-150: 12px;
  --spacing-200: 16px;
  --spacing-250: 20px;
  --spacing-300: 24px;
  --spacing-350: 28px;
  --spacing-400: 32px;
  --spacing-450: 36px;
  --spacing-500: 40px;
  --spacing-550: 44px;
  --spacing-600: 48px;
  --sampler-sem-radius-none: 0;
  --sampler-sem-radius-sm: 4px;
  --sampler-sem-radius-lg: 20px;
  --sampler-sem-radius-circle: 100%;
  --sampler-sem-radius-xsm: 2px;
  --sampler-sem-radius-md: 8px;
  --sampler-sem-radius-xmd: 12px;
  --sampler-sem-radius-xxmd: 16px;
  --sampler-theme-border-radius: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-sm-keyhint: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-keyhint: var(--sampler-sem-radius-circle);
  --sampler-comp-radius-sm-ranking-drpdwn: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-ranking-drpdwn: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-matrix: 24px;
  --sampler-comp-radius-lg-picchoice: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-wrapper: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-lg-fileupl-file: var(--sampler-sem-radius-md);
  --sampler-comp-radius-lg-fileupl-file-touch: var(--sampler-sem-radius-xxmd);
  --sampler-comp-radius-none-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-sm-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-payment: 34px;
  --sampler-comp-radius-lg-footer: 116px;
  --sampler-comp-radius-lg-footer-touch: 18px;
  font-family: inherit;
  visibility: visible;
  text-align: left;
  overflow-wrap: break-word;
  color: rgb(39, 39, 39);
  font-weight: unset;
  font-size: 16px;
  line-height: 32px;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 2rem;
}

#page-topbar {
  // background-color: #5b235c;
  background: linear-gradient(#5b235c, #7d5a7e);
}
.header-item {
  color: #fff !important;
}

.accordion-button {
  color: #fff;
}

.accordion-button:not(.collapsed) {
  color: #5b235c;
}
.sider-section {
  background-image: url("https://mtml.s3.ca-central-1.amazonaws.com/assets/cover-2.png");
  background-position-x: 10px;
  // background-position-y: 10px;
  background-repeat: no-repeat;
  // background-size: auto
}
.slider-title {
  color: #111111;
  font-family: "Poppins", Sans-serif;
  font-size: 45px;
  font-weight: bold;
  line-height: 1.3em;
}
.sub-title {
  color: #111111;
  // font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.63em;
  letter-spacing: 1.66px;
}
.sider-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  fill: #ffffff;
  color: #ffffff;
  text-transform: uppercase;
  // background-color: #5b235c;
  background: linear-gradient(#5b235c, #7d5a7e);
  border-radius: 0px 0px 0px 0px;
  padding: 18px 59px 18px 59px;

  &:hover {
    color: white;
    background: #503154;
    transform: scale(1.08);
    transition: transform 0.2s;
  }
}
.second-headline {
  color: #111111;
  font-family: "Poppins", Sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.63em;
  letter-spacing: 1.66px;
}

.nice-container {
  @media (min-width: 453px) and (max-width: 768px) {
    max-width: 700px !important;
  }

  @media (min-width: 768px) and (max-width: 850px) {
    max-width: 800px !important;
  }

  @media (min-width: 850px) {
    max-width: 1024px !important;
  }
}
.content-text {
  color: #3e3e3e;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.88em;
  letter-spacing: 0.42px;
}
